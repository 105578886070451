export interface AnalyticsConfig {
  toSegment: boolean
  toConsole: boolean
  consoleStyle: string
}

const analyticsConfigHelp = {
  toSegment: 'If true, send all tracking events to Segment.',
  toConsole: 'If true, send all tracking events to console.',
  consoleStyle:
    'A limited CSS string, which a defines how the console output should look. See https://developer.mozilla.org/en-US/docs/Web/API/console#styling_console_output.',
}

export let analyticsConfig: AnalyticsConfig = {
  toSegment: false,
  toConsole: false,
  consoleStyle: 'font-weight: bold; color: #4a957a',
}

export const getAnalyticsConfig = function (): AnalyticsConfig {
  return { ...analyticsConfig }
}

function setAnalyticsConfig(newAnalyticsConfig: Partial<AnalyticsConfig>) {
  if (typeof window === 'undefined') return

  // Poor man's deep copy
  const lastState = JSON.parse(JSON.stringify(analyticsConfig))

  // Maintain previous options if none are specified
  analyticsConfig = { ...analyticsConfig, ...newAnalyticsConfig }

  // Store our new state
  if (localStorage) {
    localStorage.setItem('analyticsConfig', JSON.stringify(analyticsConfig))
  }

  // Emit output
  if (analyticsConfig.toConsole && !lastState.toConsole) {
    emitHeader()
  }
  emitProperties()
}

function emitHeader() {
  const groups = [
    {
      text: '%c✨ Realm Analytics Config ✨',
      styles: [
        "font-family:'Moderat',sans-serif;color:white;border-radius:6px;padding:8px 16px;margin-bottom:4px;font-size:16px;background:#4a957a;",
      ],
    },
    {
      text: '%cTo configure, enter %csetAnalyticsConfig(options)%c current options are:',
      styles: [
        "font-family:'Moderat',sans-serif;font-size:12px;color:#4a957a;padding-left:8px",
        'font-family:monospace;font-size: 12px;color:#6a6c6e;',
        "font-family:'Moderat',sans-serif;font-size:12px;color:#4a957a;",
      ],
    },
  ]
  console.log(
    groups.map((g) => g.text).join('%c\r\n'),
    ...groups.reduce((memo, g) => memo.concat([...g.styles, '']), [] as Array<string>)
  )
}

function emitProperty(property: string) {
  const msg = analyticsConfigHelp[property]
  const val = analyticsConfig[property]
  console.groupCollapsed(
    `%c${property}%c: ${val}`,
    'font-family:monospace;font-size:12px;color:#777;padding-left:16px;cursor:pointer;',
    'font-family:monospace;font-size:12px;color:#6a6c6e;cursor:pointer;'
  )
  console.log(`%c${msg}`, "font-family:'Moderat';font-size:12px;padding-left:8px;color:#4a957a")
  console.groupEnd()
}

function emitProperties() {
  Object.keys(analyticsConfigHelp).forEach((prop) => emitProperty(prop))
}

function deserializeAnalyticsConfig() {
  try {
    const analyticsConfigString = localStorage.getItem('analyticsConfig')
    if (!analyticsConfigString) return null
    return JSON.parse(analyticsConfigString)
  } catch {
    /* failing to parse is ok. */
    return null
  }
}

function initializeAnalyticsConfig() {
  // This means nothing if we're not in the browser.
  if (typeof window === 'undefined') return

  // Recover config from localStorage, if any
  if (localStorage) {
    const newAnalyticsConfig = deserializeAnalyticsConfig()
    if (newAnalyticsConfig) {
      analyticsConfig = newAnalyticsConfig
    } else {
      analyticsConfig = {
        ...analyticsConfig,
        toSegment: !!document.body.dataset.segmentKey && document.body.dataset.ml !== 'true',
        toConsole: !document.body.dataset.segmentKey,
      }
    }
  }

  // log instrutions to console if appropriate
  if (analyticsConfig.toConsole) {
    emitHeader()
    emitProperties()
  }

  // mount method to set analytics config onto the global window.
  ;(window as any).setAnalyticsConfig = setAnalyticsConfig
}

initializeAnalyticsConfig()
