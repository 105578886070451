import React, {
  ButtonHTMLAttributes,
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
} from 'react'
import cx from 'classnames'

import styles from './CoreButton.module.scss'

export type CoreButtonKind =
  | 'primary'
  | 'significant'
  | 'secondary'
  | 'tertiary'
  | 'disabled'
  | 'ghost'
  | 'negative'
  | 'inverted'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  size?: 's' | 'm' | 'l' | 'xl'
  kind?: CoreButtonKind
  icon?: ReactNode
  iconAlignment?: 'left' | 'center'
}

const CoreButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { text, icon, type = 'button', size = 'l', kind = 'primary', className, onClick, disabled, iconAlignment, ...rest },
  ref
) => {
  const handleClick = useCallback(
    (event) => {
      if (disabled) {
        event.preventDefault()
        return false
      }

      return onClick?.(event)
    },
    [disabled, onClick]
  )

  const iconClass = useMemo(() => {
    const alignment = iconAlignment && text ? (iconAlignment == 'left' ? styles.left : styles.center) : styles.center

    return cx(styles.iconWrapper, alignment)
  }, [text, iconAlignment])

  return (
    <button
      disabled={disabled}
      type={type == 'button' ? 'button' : type == 'reset' ? 'reset' : 'submit'}
      ref={ref}
      {...rest}
      onClick={handleClick}
      className={cx(styles.button, size, kind, className)}
    >
      {icon && <div className={iconClass}>{icon}</div>}
      {text && <div className={styles.buttonText}>{text}</div>}
    </button>
  )
}

export default forwardRef(CoreButton)
